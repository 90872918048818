<template>
    <div class="defth-parent">
        <div class="buysell-column">
            <div class="header-table">
                <span style="width:80px"> مجموع </span>
                <span> مقدار </span>
                <span> قیمت فروش </span>
            </div>
            <div class="sell-line"></div>
            <div class="body-table">
                <div style="background: linear-gradient(to right,rgba(255, 161, 161, 1) 20%,white 0%)" v-for="(trade,index) in sellTrades" :key="index" class="coin-row">
                    <span> {{ $toLocal(trade.wholePrice,$decimal[$route.params.tradeTo]) }} </span>
                    <span> {{ $toLocal(trade.amount,$decimal[$route.params.tradeFrom]) }} </span>
                    <span class="fail"> {{ $toLocal(trade.unitPrice,$decimal[$route.params.tradeTo]) }} </span>
                </div>
            </div>
        </div>
        <div class="average-column">
            <div class="avr-header">

            </div>
            <div style="background: linear-gradient(to right,rgba(33, 163, 21, 1) 20%,rgba(227, 13, 13, 1) 40%)" class="avr-line"></div>
            <div class="avr-body">
                <span class="lastprice-text"> آخرین قیمت </span>
                <span class="lastprice-toman"> {{$toLocal(lastPriceToman,0)}} </span>
                <span class="lastprice-usdt">{{$toLocal(lastPriceTether,2)}}USDT</span>
            </div>
        </div>
        <div class="buysell-column">
            <div class="header-table">
                <span style="width:80px"> مجموع </span>
                <span> مقدار </span>
                <span> قیمت فروش </span>
            </div>
            <div class="buy-line"></div>
            <div class="body-table">
                <div style="background: linear-gradient(to right,rgba(167, 255, 159, 1) 20%,white 0%)"  v-for="(trade,index) in sellTrades" :key="index" class="coin-row">
                    <span> {{ $toLocal(trade.wholePrice,$decimal[$route.params.tradeTo]) }} </span>
                    <span> {{ $toLocal(trade.amount,$decimal[$route.params.tradeFrom]) }} </span>
                    <span class="success"> {{ $toLocal(trade.unitPrice,$decimal[$route.params.tradeTo]) }} </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Defth',
    data() {
        return {
            sellTrades:[
                {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
    {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
    {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
    {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
    {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
    {
        orderType:'LIMITED_SELL',
        amount:0.003,
        wholePrice: 13634826.34,
        unitPrice: 97000000,
    },
            ],
            buyTrades:[
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
                {
                    orderType:'LIMITED_BUY',
                    amount:0.003,
                    wholePrice: 13634826.34,
                    unitPrice: 97000000,
                },
            ],
            lastPriceToman:1133020000,
            lastPriceTether:45000,
        }
    },
}
</script>

<style lang="scss" scoped>
.header-table , .avr-header{
    height: 20%;
}
.average-column{
    display: flex;
    flex-direction: column;
    width: 20%;
    .avr-header{
        background:white;
    }
    .avr-line{
        width: 100%;
        height: 1px;
        padding: 1px 0;
    }
    .avr-body{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        align-items: center;
        padding: 8px;
        height: 100%;
        background: white;
        color: var(--grey);
        .lastprice-text{
            font-weight: 400;
            font-size: clamp(12px,1vw,14px);
        }
        .lastprice-toman{
            font-weight: 400;
            font-size: clamp(16px,1vw,18px);
        }
        .lastprice-usdt{
            font-weight: 700;
            font-size: clamp(10px,1vw,12px);
        }
    }
}
.sell-line{
    border: 1px solid var(--red);
   border-radius: 20px;
}
.buy-line{
    border: 1px solid var(--green);
   border-radius: 20px;
}
.buysell-column{
    width: 45%;
    display: flex;
    flex-direction: column;
    .header-table{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: white;
        padding: 12px 12px 4px;
        font-size: clamp(12px,1vw,14px);
        color: var(--grey);
    }
    .body-table{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        background: white;
        padding: 12px 12px 4px;
        font-size: clamp(10px,1vw,12px);
        height: 100%;
        color: var(--grey);
        .coin-row{
            max-height: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }
}
.defth-parent{
    width: 60%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    // height: 100%;
}
@media only screen and(max-width:1000px){
    .defth-parent{
        width: 100%;
    }
}
@media only screen and(max-width:700px){
    .defth-parent{
        width: 100%;
        flex-direction: column;
        row-gap: 12px;
    }
    .average-column{
        display: none;
    }
    .buysell-column{
        width: 100%;
    }
}
</style>