<template>
    <div class="buy-conteiner">
        <div class="select-order">
            <div class="btns">
                <button :class="{'active-btn':!marketClick}" @click="marketClick=false" class="limit"> حد </button>
                <button :class="{'active-btn':marketClick}" @click="marketClick=true" class="market"> بازار </button>
                <div :class="marketClick ? 'go-left':'go-right'" class="underline"></div>
            </div>
        </div>
        <div class="inputs">
            <div class="trade-input-container">
                <span class="input-title"> قیمت </span>
                <input type="text">
                <span class="price-type"> {{$coinLabel[$route.params.tradeTo]}} </span>
            </div>
            <div class="amount-fileds">
                <div class="balance">
                    <span class="balance-title"> موجودی </span>
                    <span class="balance-num"> {{$toLocal(balance,$decimal[$route.params.tradeTo])}} </span>
                    <span class="balance-title"> {{$coinLabel[$route.params.tradeFrom]}}  </span>
                </div>
                <div class="trade-input-container">
                    <span class="input-title"> مقدار </span>
                    <input type="text">
                    <span class="price-type"> {{$coinLabel[$route.params.tradeFrom]}} </span>
                </div>
                <div class="range-input-container">
                    <input v-model="amount" min="0.1" max="100" type="range">
                    <div class="range-limits">
                        <span @click="amount=100"> 100 </span>
                        <span @click="amount=75"> 75 </span>
                        <span @click="amount=50"> 50 </span>
                        <span @click="amount=25"> 25 </span>
                        <span @click="amount=0"> 0 </span>
                    </div>
                </div>

            </div>
            <date-picker 
                        v-if="marketClick==true"
                        placeholder=" تاریخ انقضا " 
                        v-model="eDate"
                        locale="fa"
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['faDateTime']"
                        :clearable="true"
                        type="datetime"
                        :min="now"
                        class="date"/>
        </div>
        <div class="details">
            <div class="numbers">
                <div @click="showDetails = !showDetails" class="row total">
                    <span class="total-text"> مجموع {{$toLocal(recievedprice,$decimal[$route.params.tradeTo])}} تومان </span>
                    <div class="detaildrop">
                        <span> جزئیات </span>
                        <inline-svg :class="{'rotate':showDetails}" class="arrow" :src="require('../../assets/icons/inputarrow.svg')" />
                    </div>
                </div>
                <div v-if="showDetails" class="row totalprice">
                    <span> مبلغ سفارش </span>
                    <div class="pricetag">
                        <span> {{$toLocal(recievedprice,$decimal[$route.params.tradeTo])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
                <div v-if="showDetails" class="row feeprice">
                    <span> کارمزد ({{'%'+fee}}) </span>
                    <div class="pricetag">
                        <span> {{$toLocal(recievedprice,$decimal[$route.params.tradeTo])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
                <div v-if="showDetails" class="row recievedprice">
                    <span> دریافتی شما </span>
                    <div class="pricetag">
                        <span> {{$toLocal(recievedprice,$decimal[$route.params.tradeTo])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
            </div>

        </div>
        <button class="sell"> فروش {{$coinLabel[$route.params.tradeFrom]}} </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                marketClick:true,
                balance:2000000,
                amount:0,
                eDate:null,
                recievedprice:12000000,
                showDetails:true,
                fee:0.4,
            }
        },
        computed:{
            now() {
                return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
            },
        }
    }
</script>

<style lang="scss" scoped>
.sell{
    padding: 8px 0px;
    border-radius: 4px;
    background: var(--red);
    color: white;
}
.rotate{
    transform: rotate(-180deg) !important;
}
.details{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    .numbers{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .total-text{
            word-spacing: 5px;
        }
        .detaildrop{
            cursor: pointer;
            &:hover{
                color: var(--primary-color);
                fill: var(--primary-color) !important;
            }
            .arrow{
                transform: rotate(0deg);
                transition: 500ms;
            }
        }
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .pricetype{
            color: var(--grey);
        }
    }
}
.inputs{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    .range-input-container{
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        background: var(--light-grey);
        border-radius: 4px;
        input{
            direction: ltr !important;
        }
        input {
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        border-radius: 5px;  
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        }

        input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 15px;
        border-radius: 10%; 
        background: white;
        cursor: pointer;
        }

        input::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #04AA6D !important;
        cursor: pointer;
        }
        .range-limits{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            span{
                cursor: pointer;
            }
        }
    }
    .trade-input-container{
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        background: var(--light-grey);
        border-radius: 4px;
        span{
            font-size: clamp(10px,1vw,14px);
        }
        .price-type{
            color: var(--grey);
        }
        input{
            width: 70%;
            background: transparent;
        }
    }
    .amount-fileds{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    .balance{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        justify-content: flex-end;
        .balance-num{
            font-size: clamp(11px,1vw,13px);
        }
    }
    .balance-title{
        color: var(--grey);
        font-size: clamp(10px,1vw,12px);
    }
}




.go-left{
    left: 10px;
}
.go-right{
    right: 10px;
}
.buy-conteiner{
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    width: 100%;
}
.select-order{
    display: flex;
    width: 100%;
    background: var(--light-grey);
    border-radius: 0px 0px 8px 8px;
}
.btns{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
    padding: 6px;
    .underline{
        bottom: 7px;
        position: absolute;
        border-radius: 12px;
        width: 20px;
        height: 3px;
        background: var(--green);
    }
    button{
        color: var(--grey);
        font-size: clamp(12px,1vw,14px);
    }
    .active-btn{
        color: var(--black-fee) !important;
    }
}
@media only screen and(max-width:400px){

    .total{
        flex-direction: column !important;
        row-gap: 10px;
    }

}
</style>