<template>
    <div class="selected-coin">
        <div class="info">
            <img class="coin-icon" :src="'/coins/' +$route.params.tradeFrom+ '.png'" alt="">
            <div class="coinlabel">
                <span class="tradeto">
                    {{$coinLabel[$route.params.tradeFrom] + ' ' + $coinUnit[$route.params.tradeFrom]}}  
                </span>
                <span> / </span>
                <span class="tradefrom">
                    {{ $coinLabel[$route.params.tradeTo] }}
                </span>
            </div>
            <span> {{$toLocal(data.lastPrice,$decimal[$route.params.tradeTo])}} </span>
            <div class="changes">
                <inline-svg :class="data.last24HourChange > 0 ?'success' :'fail'" :src="require('../../assets/icons/changes.svg')" />
                <span class="success" v-if="data.last24HourChange > 0"> {{$toLocal(data.last24HourChange,2)}}%+</span>
                <span class="fail" v-if="data.last24HourChange < 0"> {{$toLocal(Math.abs(data.last24HourChange),2)}}%- </span>
                <span class="fail" v-if="data.last24HourChange == 0"> 0 </span>
            </div>
        </div>
        <div class="table">
            <table>
                    <tr>
                        <td> کمترین قیمت </td>
                        <td> بالاترین قیمت </td>
                        <td> حجم بازار </td>
                    </tr>
                    <tr>
                        <td> {{$toLocal(data.minPrice)}}  <span class="coin-label">{{$coinLabel[$route.params.tradeTo]}}</span> </td>
                        <td> {{$toLocal(data.maxPrice)}}  <span class="coin-label">{{$coinLabel[$route.params.tradeTo]}}</span>  </td>
                        <td> {{$toLocal(data.last24HourVolume)}}  <span class="coin-label">{{$coinLabel[$route.params.tradeTo]}}</span>  </td>
                    </tr>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name:'CoinHeaderP2P',
    data() {
        return {
            data:[],
        }
    },
    methods:{
        async getTradeHeader(){
            this.state.loading=true
            const res = await this.$axios.get('/trades/market-info', {
                    params: {
                        weeklyDataIncluded: false,
                        market_type: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                    }
                })
            if(res){
                this.data= res.content[0] || this.data
            }
        }
    },
    mounted() {
        this.getTradeHeader()
    },
}
</script>

<style lang="scss" scoped>
.changeicon{
    width: 18px;
    height: 12px;
}
.coin-label{
    color: var(--grey);
    font-weight: 400;
    font-size: clamp(10px,1vw,12px);
}
.table{
    width: 45%;
}
table{
    width: 100%;
}
.selected-coin{
    display: flex;
    flex-direction: row;
    padding: 8px 12px;
    background: white;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}
.info{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
    .coinlabel{
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        align-items: center;
    }
    .coin-icon{
        width: 26px;
        height: 26px;
    }
    .tradeto{
        word-spacing: 5px;
        align-items: center;
    }
    .tradefrom{
        color: var(--grey);
        font-size: clamp(12px,1vw,14px);
    }
    .changes{
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and(max-width:1300px){
    .selected-coin{
        justify-content: center;
        width: 100%;
    }
}
@media only screen and(max-width:1000px){
    .table{
        display: none;
    }
    .selected-coin{
        justify-content: center;
    }
}
@media only screen and(max-width:650px){
    .coinlabel{
        display: none !important;
    }
    .info{
        width: 100%;
        justify-content: space-between;
        .changes{
            font-size: 12px;
        }
    }
}
</style>