import { render, staticRenderFns } from "./CoinHeader.vue?vue&type=template&id=953a0da2&scoped=true&"
import script from "./CoinHeader.vue?vue&type=script&lang=js&"
export * from "./CoinHeader.vue?vue&type=script&lang=js&"
import style0 from "./CoinHeader.vue?vue&type=style&index=0&id=953a0da2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953a0da2",
  null
  
)

export default component.exports