<template>
    <div class="shopnav">
        <div class="notif-container">
            <img src="../../assets/icons/sidebarNotifs.svg" alt="" class="notification">
        </div>
        <div class="profile">
            <inline-svg :src="require('../../assets/icons/inputarrow.svg')" />
            <img src="../../assets/icons/avatar.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name:'ShopNav'
    }
</script>

<style lang="scss" scoped>
.profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}
.notif-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: var(--light-grey);
    border-radius: 4px;
    width: 36px;
    height: 36px;
}
.shopnav{
    padding: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-end;
}
@media only screen and(max-width:1300px){
    .shopnav{
        max-width: 500px;
        display: none;
    }
}
</style>