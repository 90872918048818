<template>
    <div class="shop">
        <div class="btns">
            <button @click="buySelected=true" :class="{'buy-active':buySelected}" class="buy"> خرید </button>
            <button @click="buySelected=false" :class="{'sell-active':!buySelected}" class="sell"> فروش </button>
        </div>
        <BuyCrypto v-if="buySelected" />
        <SellCrypto v-if="!buySelected" />
    </div>
</template>

<script>
import BuyCrypto from './BuyCrypto.vue';
import SellCrypto from './SellCrypto.vue';
    export default {
        name:'Shop',   
        components:{
            BuyCrypto,
            SellCrypto
        },
        data() {
            return {
                buySelected:true,
            }
        },
    }
</script>

<style lang="scss" scoped>
.shop{
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.btns{
    width: 100%;
    button{
        width: 50%;
        border-radius: 4px;
        padding: 5px;
    }
    .sell{
        background: rgba(226, 203, 203, 1);
        color: rgba(177, 153, 153, 1);
    }
    .buy-active{
        border: 2px solid rgba(33, 163, 21, 1);
        background: rgba(226, 255, 218, 1);
        color: var(--green) !important;
    }
    .buy{
        background: rgba(178, 227, 174, 1);
        color: rgba(113, 178, 107, 1);
    }
    .sell-active{
        border: 2px solid rgba(227, 13, 13, 1);
        background: rgba(255, 228, 228, 1);
        color: rgba(227, 13, 13, 1) !important;

    }
}
@media only screen and(max-width:1300px){
    .shop{
        width: 100%;
        max-width: 500px;
        background: white;
        border-radius: 12px;
    }
}
</style>